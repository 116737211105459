import React from "react";

import Layout from "@faq/components/Layout";

const NotFoundPage = () => (
  <Layout
    breadcrumb={[{ label: "404" }]}
  >
    <h1>Oups !</h1>
    <h2>Cette page d’existe pas (erreur 404).</h2>
    <div style={{ marginTop: "50px" }}>
      Nous n’avons pas trouvé la page que vous recherchiez.
    </div>
  </Layout>
);

export default NotFoundPage;
